import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  getImageWaterMark(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "settings/getImageWaterMark" + Session.getToken();    
    return instance.post(url,params);
  },   
}

export default servicesAPI;